.admin-layout {
    display: flex;
    height: 100vh;
}

.admin-content {
    flex: 1;
    padding: 20px;
    background: #f8f9fa;
    overflow-y: auto;
}


/* Sidebar container styles */
.sidebar {
    width: 200px;
    height: 100vh;
    background-color: #2e2e2e; /* Dark blue-gray color */
    color: #ecf0f1; /* Light gray color */
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Sidebar heading */
.sidebar h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 20px;
    color: #ecf0f1;
}

/* Navigation list styles */
.sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar ul li {
    margin-bottom: 20px;
}

/* Links inside the sidebar */
.sidebar ul li a {
    text-decoration: none;
    color: #ecf0f1; /* Link color */
    font-size: 16px;
    padding: 10px 15px;
    display: block; /* Make the entire area clickable */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effects */
}

/* Hover and active styles */
.sidebar ul li a:hover {
    background-color: #34495e; /* Slightly lighter shade for hover */
    color: #ffffff; /* White text on hover */
}

.sidebar ul li a.active-link {
    background-color: #4eac6d; /* Teal color for active link */
    color: #ffffff; /* White text */
    font-weight: bold; /* Highlight the active link */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .sidebar {
        width: 200px; /* Narrower sidebar for smaller screens */
    }

    .sidebar h2 {
        font-size: 18px;
    }

    .sidebar ul li a {
        font-size: 14px;
        padding: 8px 12px;
    }
}

/* User table styles */
.manage-users {
    padding: 20px;
    background: #f8f9fa;
    width: 100%;
}

.manage-users h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #2c3e50;
}

.user-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.user-table th,
.user-table td {
    padding: 10px 15px;
    border: 1px solid #ddd;
}

.user-table th {
    background-color: #2e2e2e;
    color: #ecf0f1;
}

.user-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.user-table button {
    padding: 5px 10px;
    background-color: #4eac6d;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease;
}

.user-table button:hover {
    background-color: #307a46;
}

.edit-modal,
.create-modal {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.form-group label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}
.form-group input {
    padding: 4px;
}
.form-group select {
    padding: 4px;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
}

.modal-actions button {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
}
.modal-actions-save, .modal-actions-create{
    background-color: #4eac6d;
    color: white;
}
.modal-actions-save:hover, .modal-actions-create:hover{
    background-color: #307a46;
}
.create-user-button {
    display: block;
    margin: 10px 10px ;
    padding: 10px 20px;
    background-color: #4eac6d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.create-user-button:hover {
    background-color: #307a46;
}